<template>
  <v-dialog persistent fullscreen v-model="shown">
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">{{`${device.deviceName}`}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="onClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card class="mb-4 mt-4">
              <v-card-title class="centered headline">
                Statistics
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div class="body-1 ma-2 black--text">{{`Total Screenshots: ${this.totalNumberOfScreenshots}`}}</div>
                <div class="body-1 ma-2 black--text">{{`Screen Time: ${this.totalScreenshotableTimeString}`}}</div>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <div class="body-1 ma-2 black--text" v-on="on">{{`Actual Screenshot Frequency: ${averageTimeBetweenScreenshots === false ? '...calculating...' : averageTimeBetweenScreenshots}`}}</div>
                  </template>
                  <span>View "Screenshots NOT Taken" events to understand why the frequency is different than the target average frequency.</span>
                </v-tooltip>
                <div class="body-1 ma-2 black--text">{{`Total Times Screenshot NOT Taken: ${this.totalTimesScreenshotNotTaken}`}}</div>
                <div class="body-1 ma-2 black--text">{{`Screen On / Off: ${totalScreenOnOffs} times`}}</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="d-flex" v-if="current > currentShutoff">
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              outlined
              color="primary"
              @click="loadMore">
              {{loading ? '' : 'load 6 more hours'}}
            </v-btn>
            <v-spacer></v-spacer>
          </v-col>
          <v-col cols="12" md="6">
            <screen-time-chart :screen-usage="screenUsage" :current="current" class="mb-3" style="height: 100%;" />
          </v-col>
          <v-col cols="12" md="6">
            <app-usage-chart :app-times="appTimes" :current="current" style="height: 100%;" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import EventCache from "../../services/EventCache";
  import logging from "../../lib/logging";
  import ScreenTimeCalcs from "../../services/ScreenTimeCalcs";
  import AppUsageChart from "../Charts/AppUsageChart";
  import ScreenTimeChart from "../Charts/ScreenTimeChart";
  import moment from "moment-timezone";
  import AWSClockSkewHandler from "../../aws/AWSClockSkewHandler";
  import mobileBackButtonMixin from "../../mixins/mobileBackButtonMixin";
  import iOSScrollHack from "@/services/iOSScrollHack";

  let eventSet = new Set()

  export default {
    name: "ScreenTimeDialog",
    mixins: [
      mobileBackButtonMixin('onClose')
    ],
    components: {ScreenTimeChart, AppUsageChart},
    props: {
      device: {
        type: Object,
        required: true
      },
      showDialog: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        message: null,
        showMessage: false,
        loading: false,
        current: AWSClockSkewHandler.getTime(),
        events: [],
        currentShutoff: AWSClockSkewHandler.getTime() - 14 * 24 * 60 * 60 * 1000,
        shown: false,
        averageTimeBetweenScreenshots: false,
        totalScreenshotableTime: 0,
        totalScreenshotableTimeString: "",
        totalNumberOfScreenshots: 0,
        totalTimesScreenshotNotTaken: 0,
        totalScreenOnOffs: 0
      }
    },
    beforeMount() {
      this.setShown(this.$props.showDialog)
    },
    mounted() {
      console.log('screen time dialog mounted')
      this.current = AWSClockSkewHandler.getTime()
      if(this.device && this.device.deviceId) {
        this.loadEvents()
      }
    },
    beforeDestroy() {
      console.log('beforeDestroy')
      this.cleanupForClose()
    },
    methods: {
      setShown(val) {
        //fix for iOS "scroll to top" issue, see https://github.com/vuetifyjs/vuetify/issues/3875
        this.shown = val
        iOSScrollHack.set(val)
      },
      onClose() {
        this.cleanupForClose()
      },
      cleanupForClose() {
        this.events = []
        eventSet = new Set()
        this.current = AWSClockSkewHandler.getTime()
        this.setShown(false)

        this.$emit('on-close')
      },
      async loadMore() {
        await this.loadEvents()
      },
      async loadEvents() {
        if(!this.device || !this.device.deviceId) {
          return
        }

        this.loading = true
        try {

          // let {events, current} = await eventsApi.getChunkOfEvents(this.device.deviceId, this.current)
          let until = this.current - 6 * 60 * 60 * 1000
          let events = await EventCache.getEventsForIdentity(this.device.accountId, this.device.deviceId, this.current, until)

          this.current = until

          for(let e of events) {
            if(!eventSet.has(e.key)) {
              this.events.push(Object.freeze(e))
              eventSet.add(e.key)
            }
          }

          if(this.current > AWSClockSkewHandler.getTime() - 24 * 60 * 60 * 1000) {
            await this.loadEvents()
          } else {
            this.events.sort(ScreenTimeCalcs.orderScreenTimeEvents);

            let { totalScreenshotableTime,
              totalNumberOfScreenshots,
              averageTimeBetweenScreenshots,
              totalTimesScreenshotNotTaken,
              totalScreenshotableTimeString,
              totalScreenOnOffs } = ScreenTimeCalcs.calculateScreenStats(this.events)

            this.totalScreenshotableTime = totalScreenshotableTime
            this.totalNumberOfScreenshots = totalNumberOfScreenshots
            this.averageTimeBetweenScreenshots = averageTimeBetweenScreenshots
            this.totalTimesScreenshotNotTaken = totalTimesScreenshotNotTaken
            this.totalScreenshotableTimeString = totalScreenshotableTimeString
            this.totalScreenOnOffs = totalScreenOnOffs

            this.loading = false

            this.showMessage = true
            this.message = `Loaded to ${this.getSinceString}`
            this.loading = false
          }
        } catch (error) {
          console.log(error)
          logging.notify(error)
          alert('error loading events... please try again.  If this continues, contact support@truple.io')
        } finally {
          //shouldn't be necessary, but sometimes when no events are there it fails to show the load more button
          if(this.events && this.events.length === 0) {
            try {
              this.forceRender()
            } catch(e) {
              // eslint-disable-next-line no-console
              console.log(e)
            }
          }
        }
      }
    },
    computed: {
      getSinceString() {
        return moment(this.current).format('LLLL')
      },
      screenUsage() {
        if(!this.events || this.events.length === 0) {
          return []
        }
        return ScreenTimeCalcs.calculateScreenUsage(this.events)
      },
      appTimes () {
        if(!this.events || this.events.length === 0) {
          return []
        }
        return ScreenTimeCalcs.calculateAppTimes(this.events)
      },
    },
    watch: {
      device() {
        console.log('device changed')
        if(this.$props.device && this.$props.device.deviceId) {
          this.current = AWSClockSkewHandler.getTime()
          this.loadEvents()
        }
      },
      showDialog() {
        this.setShown(this.$props.showDialog)
      }
    }
  }
</script>

<style scoped>

</style>