<template>
  <v-dialog persistent fullscreen v-model="shown">
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title v-if="!$store.state.isMobile" class="white--text">{{`${device.deviceName}`}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="pt-6 mr-1" style="display: inline-block" v-if="!$store.state.isMobile">
          <v-text-field
            v-model="tempEventFilter"
            :loading="eventFilterLoading"
            color="accent"
            placeholder="Search"
            outlined
            dense
            clearable
            :disabled="loading"
            class="text--white"
            @change="startFilterTimeout"
            background-color="white"></v-text-field>
        </div>
        <div class="pt-6 mr-1" style="display: inline-block">
          <v-select
            v-model="selectedTypeFilters"
            :items="correctedEventTypes"
            color="accent"
            multiple
            placeholder="Event Type"
            outlined
            dense
            :disabled="loading"
            class="text--white"
            background-color="white">
          </v-select>
        </div>
        <div class="pt-6" style="display: inline-block" >
          <v-select
            v-model="selectedRiskFilters"
            :items="riskLabelsByValue"
            multiple
            color="accent"
            placeholder="Risk"
            outlined
            dense
            :disabled="loading"
            class="text--white"
            background-color="white">

          </v-select>
        </div>
        <v-btn icon dark @click="onClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
        <v-card-text class="pl-0 pr-0 pb-0">
          <div class="pa-1 mt-5 text-center" v-if="filteredEvents.length <= 0 && !loading">
            <span class="headline">No events.  Try loading more, or clearing filters.</span>
            <div class="d-flex text-h6" v-if="current > currentShutoff">
              <v-spacer></v-spacer>
              load
              &nbsp;
              <v-btn
                  :loading="loading6"
                  :disabled="loading"
                  outlined
                  color="primary"
                  @click="loadMore">
                6
              </v-btn>
              &nbsp;
              <v-btn
                  :loading="loading24"
                  :disabled="loading"
                  outlined
                  color="primary"
                  @click="load24MoreHours">
                24
              </v-btn>
              &nbsp;
              more hours
              <v-spacer></v-spacer>
            </div>
          </div>
          <v-timeline v-else dense class="pl-1 pr-1 ml-0 mr-0 pt-1 pb-1">
            <v-virtual-scroll
              :items="filteredEvents"
              :item-height="eventCardHeight"
              :height="scrollerHeight"
              :bench="1"
            >
              <template v-slot="{ index, item }">
                <v-timeline-item v-if="!item.loadMore" :icon="getIconName(item)" :color="getIconColor(item)">
                  <v-card>
                    <v-card-title class="pt-1 pb-0 text-truncate">
                      {{(item.encrypted && item.decrypted || !item.encrypted) ? item.eventTitle : item.eventType}}
                    </v-card-title>
                    <v-card-text class="pb-1">
                      <div class="text-truncate clickable"
                           @click="openEventDialogForEvent(item)"
                      >
                        <span v-if="item.eventType !== 'App Opened' && item.eventType !== 'App Closed'">
                          {{(item.encrypted && item.decrypted || !item.encrypted) ? (item.eventBody ? item.eventBody : '&nbsp;') : 'Failed to decrypt'}}
                        </span>
                        <span v-else>
                          {{(item.encrypted && item.decrypted || !item.encrypted) ? (item.packageName ? item.packageName : '&nbsp;') : 'Failed to decrypt'}}
                        </span>

                      </div>
                      <div class="caption text-truncate">
                        <span>{{item.timestampString}}</span>&nbsp;<span>{{item.encrypted ? keyEmoji : ''}}</span>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
                <div v-else class="d-flex text-h6">
                  <v-spacer></v-spacer>
                  load
                  &nbsp;
                  <v-btn
                      :loading="loading6"
                      :disabled="loading"
                      outlined
                      color="primary"
                      @click="loadMore">
                    6
                  </v-btn>
                  &nbsp;
                  <v-btn
                      :loading="loading24"
                      :disabled="loading"
                      outlined
                      color="primary"
                      @click="load24MoreHours">
                    24
                  </v-btn>
                  &nbsp;
                  more hours
                  <v-spacer></v-spacer>
                </div>
              </template>
            </v-virtual-scroll>
          </v-timeline>
          <v-speed-dial
              v-model="fab"
              bottom
              right
              fixed
          >
            <template v-slot:activator>
              <v-btn
                  v-model="fab"
                  color="accent"
                  dark
                  fab
                  bottom
                  right
              >
                <v-icon v-if="fab">
                  close
                </v-icon>
                <v-icon v-else>
                  menu
                </v-icon>
              </v-btn>
            </template>
            <v-tooltip v-model="tooltip" left nudge-left="25">
              <v-btn
                  slot="activator"
                  fab
                  color="primary"
                  href="https://blog.truple.io/2020/11/14/event-timeline-legend.html"
                  target="_blank">
                <v-icon>vpn_key</v-icon>
              </v-btn>
              <span>View Event Legend</span>
            </v-tooltip>
            <v-tooltip v-model="tooltip2" left nudge-left="25" v-if="!$store.state.inApp">
              <v-btn
                  slot="activator"
                  fab
                  color="primary"
                  @click="downloadEventLog"
                  target="_blank">
                <v-icon>file_download</v-icon>
              </v-btn>
              <span>Download Event Log</span>
            </v-tooltip>
          </v-speed-dial>
        </v-card-text>
    </v-card>
    <v-snackbar
      v-model="showMessage"
      :bottom="true">
      {{ message }}
      <v-btn icon small text color="primary" @click="showMessage = false"><v-icon>close</v-icon></v-btn>
    </v-snackbar>

    <single-event-dialog :show-dialog="showEventDialog" :item="eventDialogItem" @close="showEventDialog = false" @closeall="cleanupForClose" />

  </v-dialog>
</template>

<script>

  import {highRisk, riskLabelsByValue} from "../../risk"
  import {
    eventTypes,
    phoneOn,
    phoneOff,
    screenOff,
    screenOn,
    trupleAppShutdown,
    trupleAppLaunched,
    screenshotTaken,
    screenshotNotTaken,
    tamperDetected,
    appError
  } from "../../events";
  import logging from "../../lib/logging";
  import EventCache from "../../services/EventCache";
  import ScreenTimeCalcs from "../../services/ScreenTimeCalcs";
  import moment from "moment-timezone";
  import AWSClockSkewHandler from "../../aws/AWSClockSkewHandler";
  import Constants from "../../Constants";
  import SingleEventDialog from "@/components/SingleEventDialog";
  import mobileBackButtonMixin from "../../mixins/mobileBackButtonMixin";
  import iOSScrollHack from "@/services/iOSScrollHack";

  let eventFilterTimeoutHandler = null

  const pageLoadedAt = AWSClockSkewHandler.getTime()

  const eventCardHeight = 87

  let eventSet = new Set()

  export default {
    name: "EventDialog",
    components: {
      SingleEventDialog
    },
    mixins: [
      mobileBackButtonMixin('onClose')
    ],
    props: {
      device: {
        type: Object,
        required: true
      },
      risk: {
        type: String,
        required: false
      },
      eventType: {
        type: String,
        required: false
      },
      showDialog: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        showEventDialog: false,
        eventDialogItem: {},
        tooltip: true,
        tooltip2: true,
        fab: false,
        keyEmoji: Constants.keyEmoji,
        windowInnerHeight: window.innerHeight,
        message: '',
        showMessage: false,
        eventCardHeight: eventCardHeight,
        currentShutoff: AWSClockSkewHandler.getTime() - 14 * 24 * 60 * 60 * 1000,
        loading: false,
        loading24: false,
        loading6: false,
        selectedRiskFilters: [],
        tempEventFilter: '',
        eventFilter: '',
        eventFilterLoading: false,
        riskLabelsByValue: riskLabelsByValue,
        current: AWSClockSkewHandler.getTime(),
        events: [],
        shown: false,
        eventTypes: eventTypes,
        selectedTypeFilters: [],
      }
    },
    beforeMount() {
      this.setShown(this.$props.showDialog)
    },
    mounted() {
      console.log('eventDialog mounted')
      this.current = AWSClockSkewHandler.getTime()
      if(this.device && this.device.deviceId) {
        this.loadMore()
      }

      window.addEventListener('resize', this.onResize)

      if(this.$props.risk && this.selectedRiskFilters && this.selectedRiskFilters.indexOf(this.$props.risk) === -1) {
        this.selectedRiskFilters.push(this.$props.risk)
      }
      if(this.$props.eventType && this.selectedTypeFilters) {
        if(this.selectedTypeFilters.indexOf(this.$props.eventType) === -1) {
          this.selectedTypeFilters.push(this.$props.eventType)
        }
      }
    },
    beforeDestroy() {
      console.log('eventDialog beforeDestroy')
      this.cleanupForClose()

      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.onResize)
      }
    },
    methods: {
      setShown(val) {
        //fix for iOS "scroll to top" issue, see https://github.com/vuetifyjs/vuetify/issues/3875
        this.shown = val
        iOSScrollHack.set(val)
      },
      downloadEventLog() {

        let a = document.createElement('a')
        let blob = new Blob([JSON.stringify({
          downloadedThrough: this.current,
          downloadedThroughString: new Date(this.current).toString(),
          downloadedFrom: pageLoadedAt,
          downloadedFromString: new Date(pageLoadedAt).toString(),
          events: this.events
        }, null, 2)], { type: 'application/json' })
        let url = URL.createObjectURL(blob)

        a.href = url
        a.setAttribute("download", 'eventlog.json')
        a.click();
        URL.revokeObjectURL(a.href)
      },
      openEventDialogForEvent(item) {
        console.log(`open event dialog for event\n${item}`)
        this.showEventDialog = true
        this.eventDialogItem = item
      },
      onResize() {
        this.windowInnerHeight = window.innerHeight
      },
      copyToClipboard() {
        this.message = 'copied to clipboard'
        this.showMessage = true
      },
      getIconColor(event) {
        // if(event.)
        if(event.riskRating === highRisk) {
          return '#b71c1c'
        }

        switch(event.eventType) {
          case screenshotTaken: {
            return '#00796B'
          }
          case screenshotNotTaken: {
            return '#F4511E'
          }
          case tamperDetected: {
            return '#b71c1c'
          }
          case appError: {
            return '#F4511E'
          }
        }
        return '#757575'
      },
      getIconName(event) {
        switch(event.eventType) {
          case phoneOff: {
            return 'power_setting_new'
          }
          case phoneOn: {
            return 'power_setting_new'
          }
          case screenshotTaken: {
            return 'mobile_screen_share'
          }
          case screenshotNotTaken: {
            return 'warning'
          }
          case screenOn: {
            return 'play_arrow'
          }
          case screenOff: {
            return 'pause'
          }
          case tamperDetected: {
            return 'error'
          }
          case appError: {
            return 'error_outline'
          }
        }
        return undefined
      },
      startFilterTimeout() {
        clearTimeout(eventFilterTimeoutHandler)
        this.eventFilterLoading = true
        eventFilterTimeoutHandler = setTimeout(() => {
          this.eventFilter = this.tempEventFilter
          this.eventFilterLoading = false
        }, 1500)
      },
      cleanupForClose() {
        this.events = []
        eventSet = new Set()
        this.current = AWSClockSkewHandler.getTime()
        this.setShown(false)
        this.eventFilter = null
        this.tempEventFilter = null
        this.selectedRiskFilters = []
        this.selectedTypeFilters = []

        this.$emit('on-close')

      },
      onClose() {
        this.cleanupForClose()
      },
      async loadMore() {
        let numberOfHours = 6
        this.loading6 = true
        await this.loadEvents(numberOfHours)
        this.loading6 = false
      },
      async load24MoreHours() {
        let numberOfHours = 24
        this.loading24 = true
        await this.loadEvents(numberOfHours)
        this.loading24 = false
      },
      async loadEvents(numberOfHours) {
        numberOfHours = numberOfHours || 1
        if(!this.device || !this.device.deviceId) {
          return
        }

        this.loading = true
        try {

          // let {events, current} = await eventsApi.getChunkOfEvents(this.device.deviceId, this.current)
          let until = this.current - numberOfHours * 60 * 60 * 1000
          let events = await EventCache.getEventsForIdentity(this.device.accountId, this.device.deviceId, this.current, until)

          this.current = until

          for(let e of events) {
            if(!eventSet.has(e.key)) {
              this.events.push(Object.freeze(e))
              eventSet.add(e.key)
            }
          }

          if(this.filteredEvents.length < 20 && this.current > AWSClockSkewHandler.getTime() - 24 * 60 * 60 * 1000) {
            await this.loadEvents()
          } else {
            this.events.sort(ScreenTimeCalcs.orderScreenTimeEvents);

            this.loading = false

            this.showMessage = true
            this.message = `Loaded to ${this.getSinceString}`
          }
        } catch (error) {
          console.log(error)
          logging.notify(error)
          alert('error loading events... please try again.  If this continues, contact support@truple.io')
        }
      }
    },
    watch: {
      device() {
        console.log('device changed')
        if(this.$props.device && this.$props.device.deviceId) {
          this.current = AWSClockSkewHandler.getTime()
          this.loadMore()
        }
      },
      risk() {
        if(this.$props.risk && this.selectedRiskFilters) {
          if(this.selectedRiskFilters.indexOf(this.$props.risk) === -1) {
            this.selectedRiskFilters.push(this.$props.risk)
          }
        } else {
          this.selectedRiskFilters = []
        }
      },
      eventType () {
        if(this.$props.eventType && this.selectedTypeFilters) {
          if(this.selectedTypeFilters.indexOf(this.$props.eventType) === -1) {
            this.selectedTypeFilters.push(this.$props.eventType)
          }
        } else {
          this.selectedTypeFilters = []
        }
      },
      showDialog() {
        this.setShown(this.$props.showDialog)
      }
    },
    computed: {
      correctedEventTypes() {
        let et = []
        for(let e of eventTypes) {
          et.push(e.replace('Truple', 'Monitoring'))
        }
        return et
      },
      scrollerHeight() {
        return (this.windowInnerHeight - Constants.titleBarHeight - 16) + 'px'
      },
      getSinceString() {
        return moment(this.current).format('LLLL')
      },
      filteredEvents() {
        let ret = []
        let riskSet = new Set(this.selectedRiskFilters)
        let eventTypeSet = new Set(this.selectedTypeFilters)
        if(eventTypeSet.has(trupleAppLaunched.replace('Truple', 'Monitoring'))) {
          eventTypeSet.add(trupleAppLaunched)
        }
        if(eventTypeSet.has(trupleAppShutdown.replace('Truple', 'Monitoring'))) {
          eventTypeSet.add(trupleAppShutdown)
        }

        let lowerCaseEventFilter = this.eventFilter ? this.eventFilter.toLowerCase() : ''

        for(let i of this.events) {
          if(riskSet.size > 0) {
            if(!riskSet.has(i.riskRating)) {
              continue
            }
          }

          if(eventTypeSet.size > 0) {
            if(!eventTypeSet.has(i.eventType)) {
              continue
            }
          }

          if(lowerCaseEventFilter.length > 0) {

            let eventType = i.eventType ? i.eventType.toLowerCase() : ''
            let eventTitle = i.eventTitle ? i.eventTitle.toLowerCase() : ''
            let eventBody = i.eventBody ? i.eventBody.toLowerCase() : ''
            if(!eventType.includes(lowerCaseEventFilter) &&
              !eventTitle.includes(lowerCaseEventFilter) &&
              !eventBody.includes(lowerCaseEventFilter)) {
              continue
            }
          }
          if(i.eventType && (i.eventType === trupleAppShutdown || i.eventType === trupleAppLaunched)) {
            // i.eventTitle = i.eventTitle.replace('Truple ', 'Best Accountability ') //throws odd error I don't understand
            const clone = JSON.parse(JSON.stringify(i))
            clone.eventTitle = clone.eventTitle.replace('Truple', 'Monitoring')
            clone.eventType = clone.eventType.replace('Truple', 'Monitoring')
            ret.push(clone)
          } else {
            ret.push(i)
          }
        }

        if(this.current > this.currentShutoff) {
          ret.push({
            loadMore: true
          })
        }

        return ret
      }
    }
  }
</script>

<style scoped>
  .high-risk {
    border: 0.2em solid #b71c1c;
  }
</style>