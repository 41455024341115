<template>
  <v-dialog v-model="shown" max-width="600">
    <v-card>
      <v-card-title>
        <span>{{item.eventType}}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close"><v-icon color="primary">close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <div>
          <span>{{(item.encrypted && item.decrypted || !item.encrypted) ? (item.eventBody ? item.eventBody : '&nbsp;') : 'Failed to decrypt'}}</span>
        </div>
        <div v-if="isUrl && ((item.encrypted && item.decrypted) || !item.encrypted)">
          <v-btn color="primary"
                 class="ml-2"
                 x-small
                 text
                  @click="openSite">Open Website</v-btn>
          <v-btn color="primary"
                 class="ml-2"
                 x-small
                 text
                 @click="openAnalyzer"><span>{{`${item.unknown ? 'Submit for analysis' : 'Open analyzer'}`}}</span><span v-if="item.unknown" class="ml-1">{{item.unknown ? unknownEmoji : ''}}</span></v-btn>
        </div>
        <div v-if="item.appName &&  ((item.encrypted && item.decrypted) || !item.encrypted)">
          {{`App Name: ${item.appName}`}}
        </div>
        <div v-if="item.packageName &&  ((item.encrypted && item.decrypted) || !item.encrypted)">
          {{`Package: ${item.packageName}`}}
        </div>
<!--        <div>-->
<!--          {{item}}-->
<!--        </div>-->

        <div>{{item.timestampString}}</div>&nbsp;<span>{{item.encrypted ? keyEmoji : ''}}</span>

        <v-btn text color="primary" x-small @click="openEventLegend">Open Event Legend</v-btn>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close" block color="primary">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Constants from "@/Constants"
import AndroidInterface from "@/services/AndroidInterface";
import mobileBackButtonMixin from "@/mixins/mobileBackButtonMixin";
import Utils from "@/Utils";
import { contentChanged } from '@/events'
import StoreConstants from "@/StoreConstants";

export default {
  name: "SingleEventDialog",
  mixins: [
    mobileBackButtonMixin('close')
  ],
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object
    }
  },
  data() {
    return {
      keyEmoji: Constants.keyEmoji,
      unknownEmoji: Constants.unknownEmoji
    }
  },
  methods: {
    async openAnalyzer() {

      let host = null
      let url = Utils.getUrl(this.item.eventBody)
      if(url) {
        host = url.host
        if(host.startsWith('www.')) {
          host = host.substring(4)
        }
      }

      await this.$store.commit(StoreConstants.commits.setUnknown, {
        sites: [host],
        apps: []
      })

      this.$emit('closeall')

      await Utils.sleep(250)

      const route = {
        path: 'rating'
      }
      if(this.item.unknown) {
        route.query = {
          submit: true
        }
      }

      this.$router.push(route)
    },
    openEventLegend() {
      let url = 'https://blog.truple.io/2020/11/14/event-timeline-legend.html'
      if(this.$store.state.inApp) {
        AndroidInterface.openWebsiteInBrowser(url)
      } else {
        window.open(url, '_blank')
      }
    },
    openSite() {
      try {

        let str = this.item.eventBody
        if(!str.startsWith('https://')) {
          str = 'https://' + str
        }

        if(this.$store.state.inApp) {
          AndroidInterface.openWebsiteInBrowser(str)
        } else {
          window.open(str, '_blank')
        }
      } catch(e) {
        console.log(e)
        alert('error opening site: ' + e.toString())
      }
    },
    close() {
      this.$emit('close')
    }
  },
  computed: {
    shown: {
      get() {
        return this.showDialog
      },
      set() {
        //do nothing
      }
    },
    isUrl() {
      return this.item.eventType === contentChanged && (this.item.isWebsite || Utils.isValidUrl(this.item.eventBody))
    }
  }
}
</script>

<style scoped>

</style>