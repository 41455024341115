<template>
  <v-dialog persistent fullscreen v-model="shown">
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title v-if="!$store.state.isMobile" class="white--text">{{`${device.deviceName} (${filteredEvents.length} events)`}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="pt-6 mr-1" style="display: inline-block">
          <v-text-field
            v-model="tempEventFilter"
            :loading="eventFilterLoading"
            color="accent"
            placeholder="Search"
            outlined
            dense
            clearable
            :disabled="loading"
            class="text--white"
            @change="startFilterTimeout"
            background-color="white"></v-text-field>
        </div>
        <div class="pt-6" style="display: inline-block" >
          <v-select
            v-model="selectedRiskFilters"
            :items="riskLabelsByValue"
            multiple
            clearable
            color="accent"
            placeholder="Risk"
            outlined
            dense
            :disabled="loading"
            class="text--white"
            background-color="white">
          </v-select>
        </div>
        <v-btn icon dark @click="onClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pl-0 pr-0 pb-0">
        <div class="pa-1 mt-5 text-center" v-if="filteredEvents.length <= 0 && !loading">
          <span class="headline">No events.  Try loading more, or clearing filters.</span>
          <div class="d-flex text-h6" v-if="current > currentShutoff">
            <v-spacer></v-spacer>
            load
            &nbsp;
            <v-btn
                :loading="loading6"
                :disabled="loading"
                outlined
                color="primary"
                @click="loadMore">
              6
            </v-btn>
            &nbsp;
            <v-btn
                :loading="loading24"
                :disabled="loading"
                outlined
                color="primary"
                @click="load24MoreHours">
              24
            </v-btn>
            &nbsp;
            more hours
            <v-spacer></v-spacer>
          </div>
        </div>
        <v-timeline v-else dense class="pl-1 pr-1 ml-0 mr-0 pt-1 pb-1">

          <v-virtual-scroll
            :items="filteredEvents"
            :item-height="eventCardHeight"
            :height="scrollerHeight"
            :bench="1"
          >
            <template v-slot="{ index, item }">
              <v-timeline-item v-if="!item.loadMore" icon="public" :color="getIconColor(item)">
                <v-card>
                  <v-card-title class="pt-1 pb-0 text-truncate clickable" @click="openEventDialogForEvent(item)">
                    {{(item.encrypted && item.decrypted || !item.encrypted) ? (item.eventBody ? item.eventBody : '&nbsp;') : 'Failed to decrypt'}}
                  </v-card-title>
                  <v-card-text class="pb-1">
                    <span>{{item.timestampString}}</span>&nbsp;<span>{{item.encrypted ? keyEmoji : ''}}</span><span>{{item.unknown ? unknownEmoji : ''}}</span>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
              <div class="d-flex text-h6" v-else>
                <v-spacer></v-spacer>
                load
                &nbsp;
                <v-btn
                    :loading="loading6"
                    :disabled="loading"
                    outlined
                    color="primary"
                    @click="loadMore">
                  6
                </v-btn>
                &nbsp;
                <v-btn
                    :loading="loading24"
                    :disabled="loading"
                    outlined
                    color="primary"
                    @click="load24MoreHours">
                  24
                </v-btn>
                &nbsp;
                more hours
                <v-spacer></v-spacer>
              </div>
            </template>
          </v-virtual-scroll>
        </v-timeline>
      </v-card-text>
      <v-speed-dial
        :open-on-hover="false"
        direction="top"
        transition="slide-y-reverse-transition"
        fixed
        bottom
        right
      >

        <template v-slot:activator>
          <v-btn
            color="accent"
            fab
          >
            <v-icon>menu</v-icon>
          </v-btn>
        </template>

        <v-btn
          fab
          @click="showBreakdown"
          color="primary"
        >
          <v-icon style="transform: rotate(90deg)">bar_chart</v-icon>
        </v-btn>

      </v-speed-dial>
    </v-card>

    <v-dialog :fullscreen="true" v-model="isShowBreakdown">
      <v-card>
        <v-card-title>
          <span>Website quantities</span>
          <v-spacer />
          <v-btn outlined color="primary" @click="loadMore" :loading="loading">
            Load 6 more hours
          </v-btn>
          <v-spacer />
          <v-btn icon @click="isShowBreakdown = false" class="ml-12">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>

          <v-row>
            <v-col cols="12" md="6">
              <bar-chart
                :data="breakdownData"
                ytitle="Website"
                xtitle="Total Times Loaded"
                suffix=""
                :height="`${breakdownData.length * 15 + 30}px`"
                :colors="['#FFC107', '#00796B']"
              ></bar-chart>
            </v-col>
            <v-col cols="12" md="6">
              <bar-chart
                :data="rootDomainBreakdownData"
                ytitle="Website"
                xtitle="Total Times Loaded"
                suffix=""
                :height="`${rootDomainBreakdownData.length * 15 + 30}px`"
                :colors="['#FFC107', '#00796B']"
              ></bar-chart>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            outlined
            color="primary"
            @click="loadMore">
            {{loading ? '' : 'load 6 more hours'}}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showMessage"
      :bottom="true">
      {{ message }}
      <v-btn icon small text color="primary" @click="showMessage = false"><v-icon>close</v-icon></v-btn>
    </v-snackbar>

    <single-event-dialog :show-dialog="showEventDialog" :item="eventDialogItem" @close="showEventDialog = false" @closeall="cleanupForClose" />

  </v-dialog>
</template>

<script>
  import {highRisk, riskLabelsByValue} from "../../risk"
  import {
    eventTypes,
    contentChanged
  } from "../../events";
  import logging from "../../lib/logging";
  import EventCache from "../../services/EventCache";
  import Utils from "../../Utils";
  import ScreenTimeCalcs from "../../services/ScreenTimeCalcs";
  import moment from "moment-timezone";
  import AWSClockSkewHandler from "../../aws/AWSClockSkewHandler";
  import Constants from "../../Constants";
  import mobileBackButtonMixin from "../../mixins/mobileBackButtonMixin";
  import psl from 'psl'
  import SingleEventDialog from "@/components/SingleEventDialog";
  import iOSScrollHack from "@/services/iOSScrollHack";


  const getRootDomain = (url) => {
    try {

      if(!url) {
        return ''
      }

      if(url.substr(0, Math.min(8, url.length)).indexOf(':') === -1) {
        url = 'https://' + url
      }

      let u = new URL(url)

      let parsed = psl.parse(u.hostname).domain
      return parsed
    } catch(e) {
      console.log(`${url} - ${e}`)
      return 'error parsing domain'
    }
  }

  let eventSet = new Set()
  let eventFilterTimeoutHandler = null

  const eventCardHeight = 70

  export default {
    name: "WebsiteDialog",
    mixins: [
      mobileBackButtonMixin('onClose')
    ],
    components: {SingleEventDialog},
    props: {
      device: {
        type: Object,
        required: true
      },
      risk: {
        type: String,
        required: false
      },
      showDialog: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        showEventDialog: false,
        eventDialogItem: {},
        isShowBreakdown: false,
        keyEmoji: Constants.keyEmoji,
        unknownEmoji: Constants.unknownEmoji,
        windowInnerHeight: window.innerHeight,
        message: '',
        showMessage: false,
        eventCardHeight: eventCardHeight,
        currentShutoff: AWSClockSkewHandler.getTime() - 14 * 24 * 60 * 60 * 1000,
        currentTab: 'events',
        loading: false,
        loading6: false,
        loading24: false,
        selectedRiskFilters: [],
        tempEventFilter: '',
        eventFilter: '',
        eventFilterLoading: false,
        riskLabelsByValue: riskLabelsByValue,
        current: AWSClockSkewHandler.getTime(),
        events: [],
        shown: false,
        averageTimeBetweenScreenshots: false,
        totalScreenshotableTime: 0,
        totalScreenshotableTimeString: "",
        totalNumberOfScreenshots: 0,
        totalTimesScreenshotNotTaken: 0,
        eventTypes: eventTypes,
        selectedTypeFilters: []
      }
    },
    beforeMount() {
      this.setShown(this.$props.showDialog)
    },
    mounted() {
      console.log('website dialog mounted')
      this.current = AWSClockSkewHandler.getTime()
      if(this.device && this.device.deviceId) {
        this.loadMore()
      }

      window.addEventListener('resize', this.onResize)

      if(this.$props.risk && this.selectedRiskFilters) {
        if(this.selectedRiskFilters.indexOf(this.$props.risk) === -1) {
          this.selectedRiskFilters.push(this.$props.risk)
        }
      }
    },
    beforeDestroy() {
      console.log('beforeDestroy')
      this.cleanupForClose()

      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.onResize)
      }
    },
    methods: {
      setShown(val) {
        //fix for iOS "scroll to top" issue, see https://github.com/vuetifyjs/vuetify/issues/3875
        this.shown = val
        iOSScrollHack.set(val)
      },
      openEventDialogForEvent(item) {
        console.log(`open event dialog for event\n${item}`)
        this.showEventDialog = true
        this.eventDialogItem = item
      },
      showBreakdown() {
        this.isShowBreakdown = true
      },
      onResize() {
        this.windowInnerHeight = window.innerHeight
      },
      copyToClipboard() {
        this.message = 'website copied to clipboard'
        this.showMessage = true
      },
      getIconColor(event) {
        // if(event.)
        if(event.riskRating === highRisk) {
          return '#b71c1c'
        }
        return '#757575'
      },
      startFilterTimeout() {
        clearTimeout(eventFilterTimeoutHandler)
        this.eventFilterLoading = true
        eventFilterTimeoutHandler = setTimeout(() => {
          this.eventFilter = this.tempEventFilter
          this.eventFilterLoading = false
        }, 1500)
      },
      cleanupForClose() {
        this.events = []
        eventSet = new Set()
        this.current = AWSClockSkewHandler.getTime()
        this.setShown(false)

        this.$emit('on-close')
      },
      onClose() {
        this.cleanupForClose()
      },
      async load24MoreHours() {
        let numberOfHours = 24
        this.loading24 = true
        await this.loadEvents(numberOfHours)
        this.loading24 = false
      },
      async loadMore() {
        let numberOfHours = 6
        this.loading6 = true
        await this.loadEvents(numberOfHours)
        this.loading6 = false
      },
      async loadEvents(numberOfHours) {
        numberOfHours = numberOfHours || 1
        if(!this.device || !this.device.deviceId) {
          return
        }

        this.loading = true
        try {

          let until = this.current - numberOfHours * 60 * 60 * 1000
          let events = await EventCache.getEventsForIdentity(this.device.accountId, this.device.deviceId, this.current, until)

          this.current = until

          for(let e of events) {
            if(!eventSet.has(e.key)) {
              this.events.push(Object.freeze(e))
              eventSet.add(e.key)
            }
          }

          if(this.current > AWSClockSkewHandler.getTime() - 24 * 60 * 60 * 1000 && this.filteredEvents.length < 50) {
            await this.loadEvents()
          } else {
            this.events.sort(ScreenTimeCalcs.orderScreenTimeEvents);

            this.loading = false

            this.showMessage = true
            this.message = `Loaded to ${this.getSinceString}`
            this.loading = false
          }
        } catch (error) {
          console.log(error)
          logging.notify(error)
          alert('error loading events... please try again.  If this continues, contact support@truple.io')
        }
      }
    },
    watch: {
      device() {
        console.log('device changed')
        if(this.$props.device && this.$props.device.deviceId) {
          this.current = AWSClockSkewHandler.getTime()
          this.loadMore()
        }
      },
      risk() {
        if(this.$props.risk && this.selectedRiskFilters) {
          if(this.selectedRiskFilters.indexOf(this.$props.risk) === -1) {
            this.selectedRiskFilters.push(this.$props.risk)
          }
        } else {
          this.selectedRiskFilters = []
        }
      },
      eventType () {
        if(this.$props.eventType && this.selectedTypeFilters) {
          if(this.selectedTypeFilters.indexOf(this.$props.eventType) === -1) {
            this.selectedTypeFilters.push(this.$props.eventType)
          }
        } else {
          this.selectedTypeFilters = []
        }
      },
      showDialog() {
        this.setShown(this.$props.showDialog)
      }
    },
    computed: {
      rootDomainBreakdownData() {
        if(!this.breakdownData || this.breakdownData.length === 0) {
          return []
        } else {

          let map = {}

          for(let a of this.breakdownData) {
            let r = getRootDomain(a[0])
            if(!map[r]) {
              map[r] = [r, 0]
            }
            map[r][1]++
          }

          return Object.values(map).sort((a, b) => {
            return b[1] - a[1]
          })
        }
      },
      breakdownData() {
        if(!this.filteredEvents || this.filteredEvents.length === 0) {
          return []
        } else {


          let map = {}

          for(let e of this.filteredEvents) {
            if(!map[e.eventBody]) {
              map[e.eventBody] = [
                e.eventBody,
                0
              ]
            }

            map[e.eventBody][1]++
          }

          return Object.values(map).sort((a, b) => {
            return b[1] - a[1]
          })
        }
      },
      scrollerHeight() {
        return (this.windowInnerHeight - Constants.titleBarHeight - 16) + 'px'
      },
      getSinceString() {
        return moment(this.current).format('LLLL')
      },
      filteredEvents() {
        let ret = []
        let riskSet = new Set(this.selectedRiskFilters)

        let lowerCaseEventFilter = this.eventFilter ? this.eventFilter.toLowerCase() : ''

        for(let i of this.events) {
          if(i.eventType !== contentChanged) {
            continue
          }

          if(!i.isWebsite && !Utils.isValidUrl(i.eventBody)) {
            continue
          }

          if(riskSet.size > 0) {
            if(!riskSet.has(i.riskRating)) {
              continue
            }
          }

          if(lowerCaseEventFilter.length > 0) {
            let eventBody = i.eventBody ? i.eventBody.toLowerCase() : ''
            if(!eventBody.includes(lowerCaseEventFilter)) {
              continue
            }
          }

          ret.push(i)
        }

        if(this.current > this.currentShutoff) {
          ret.push({
            loadMore: true
          })
        }

        return ret
      }
    }
  }
</script>

<style scoped>
  .high-risk {
    border: 0.2em solid #b71c1c;
  }
</style>