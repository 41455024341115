<template>
  <v-card outlined elevation="8" style="height: 100%" >
    <v-card-title style="text-overflow: fade; overflow-x: hidden;">
      {{eventStats.deviceName}}
    </v-card-title>
    <v-card-subtitle class="pb-1" v-if="eventStats.deviceMarketName">
      <span>
        {{eventStats.deviceMarketName}}
      </span>
      <span v-if="eventStats.appVersion" class="float-right">
        {{`v${eventStats.appVersion}`}}
      </span>
    </v-card-subtitle>
    <v-card-text class="pb-4">
      <v-row no-gutters>
        <v-col cols="12" v-if="!eventStats || eventStats.totalEvents === 0" class="pr-1">
          <v-alert
            border="left"
            icon="info"
            dense
            outlined
            class="mb-1"
            color="secondary"
          >
            <template v-if="eventStats.os === 'windows' && eventStats.userAgent.indexOf('aws-apig-java') !== -1">
              <div >Please upgrade this device to the latest version.  See <a href="https://blog.truple.io/2020/03/25/windows-migration-guide.html" target="_blank">the upgrade guide</a> for instructions.</div>
              <div>
                <v-alert
                  icon="wallpaper"
                  dense
                  border="left"
                  colored-border
                  color="primary"
                  elevation="2"
                  class="mb-1 clickable"
                  @click.native="onShowScreenshots">
                  {{`View Screenshots`}}
                </v-alert>
              </div>
            </template>
            <template v-else>
              <div>{{`No event stats recorded in the past ${getPastTimeString}!`}}</div>
              <div class="caption">If this device is being used, please follow <a :href="`https://${supportDomain}/articles/misc/no-events-captured#${osToTroubleshootAnchor[eventStats.os]}`" target="_blank"> these instructions</a>.</div>

<!--              <template v-if="eventStats.os === 'android'">-->
<!--                <div>-->
<!--                  Please upgrade your android app to the latest version.-->
<!--                </div>-->
<!--                <v-alert-->
<!--                  icon="wallpaper"-->
<!--                  dense-->
<!--                  border="left"-->
<!--                  colored-border-->
<!--                  color="primary"-->
<!--                  elevation="2"-->
<!--                  class="mb-1 clickable"-->
<!--                  @click.native="onShowScreenshots">-->
<!--                  {{`View Screenshots`}}-->
<!--                </v-alert>-->
<!--              </template>-->
<!--              <div class="caption">If this device is being used, please contact <a href="mailto:support@truple.io" target="_blank">support@truple.io</a> for help troubleshooting.</div>-->
            </template>
          </v-alert>
        </v-col>

        <v-col cols="12" v-if="eventStats.totalTamperEvents > 0" class="pr-1">
          <v-alert
            border="left"
            dense
            type="error"
            class="mb-1 clickable"
            @click.native="onShowTamperEvents"
          >
            {{`Tamper events!`}}
          </v-alert>
        </v-col>

        <v-col cols="12" v-if="eventStats.totalHighRiskScreenshots > 0 && mergedCanView.screenshots" class="pr-1">
          <v-alert
            border="left"
            dense
            type="warning"
            class="mb-1 clickable"
            @click.native="onShowHighRiskScreenshots"
          >
            {{`High risk screenshots!`}}
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="eventStats.totalHighRiskWebsites > 0 && mergedCanView.websites" class="pr-1">
          <v-alert
            border="left"
            dense
            type="warning"
            class="mb-1 clickable"
            @click.native="onShowHighRiskWebsites"
          >
            {{`High risk websites!`}}
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="eventStats.totalScreenshots === 0 && eventStats.os !== 'doh' && mergedCanView.screenshots" class="pr-1">
          <v-alert
            border="left"
            dense
            type="warning"
            class="mb-1" >
            No screenshots captured
          </v-alert>
        </v-col>

        <v-col cols="12" md="6" v-if="eventStats.os !== 'doh' && mergedCanView.screenshots" class="pr-1">
          <v-alert
            icon="wallpaper"
            dense
            border="left"
            colored-border
            color="primary"
            elevation="2"
            class="mb-1 clickable"
            @click.native="onShowScreenshots">
            {{`${eventStats.totalScreenshots} Screenshots`}}
            <div v-if="mergedCanView.highRiskOnly" class="caption secondary--text">(you are limited to high risk screenshots)</div>
          </v-alert>
          <a :href="`https://${supportDomain}/articles/misc/excessive-screenshots`" target="_blank" v-if="(eventStats.totalScreenshots > 1200 && frequency === 'daily') || (eventStats.totalScreenshots > (1200 * 7) && frequency === 'weekly')" class="caption">(excessive screenshots recorded, please increase time between screenshots)</a>
        </v-col>

        <v-col cols="12" :md="eventStats.os === 'doh' ? 12 : 6" v-if="eventStats.os !== 'mac' && eventStats.os !== 'linux' && mergedCanView.websites" class="pr-1">
          <v-alert icon="public"
                   dense
                   border="left"
                   colored-border
                   color="accent"
                   elevation="2"
                   class="mb-1 clickable"
                   @click.native="onShowWebsites"
          >
            {{`${eventStats.totalWebsites} Websites ${eventStats.os === 'doh' ? '(through DNS)' : ''}`}}
            <div v-if="mergedCanView.highRiskOnly" class="caption secondary--text">(you are limited to high risk websites)</div>
          </v-alert>
        </v-col>

        <v-col cols="12" v-if="eventStats.os === 'doh' && mergedCanView.websites" class="pr-1">
          <span class="caption font-italic"><a :href="`https://${supportDomain}/articles/ios/ios-accountability-explained`" target="_blank">iPhone/iPad websites are recorded differently from other platforms.</a></span>
        </v-col>

        <v-col cols="12" :md="eventStats.os === 'doh' ? 12 : 6" class="pr-1" v-if="mergedCanView.eventTimeline">
          <v-alert icon="import_export"
                   border="left"
                   colored-border
                   color="secondary"
                   elevation="2"
                   dense
                   class="mb-1 clickable"
                   @click.native="onShowEvents"
          >
            {{`${eventStats.totalEvents} Events`}}
          </v-alert>
        </v-col>

        <v-col cols="12" md="6" v-if="(eventStats.os === 'android' || eventStats.os === 'kindle' || eventStats.os === 'windows') && mergedCanView.screenAppTime" class="pr-1">
          <v-alert icon="access_time"
                   color="secondary"
                   border="left"
                   colored-border
                   elevation="2"
                   dense
                   class="mb-1 clickable"
                   @click.native="onShowTime"
          >
            Screen & App Time
          </v-alert>
        </v-col>

        <v-col cols="12" v-if="eventStats.os === 'doh'">

        </v-col>

        <v-col cols="12" class="caption font-italic text-right" style="position: absolute; bottom: 0; right: 10px;">
          {{`in the past ${getPastTimeString}`}}
        </v-col>
      </v-row>


    </v-card-text>
  </v-card>
</template>

<script>

  import {highRisk} from "../../risk";
  import {tamperDetected} from "../../events";

  export default {
    name: "DeviceCard",
    props: {
      eventStats: {
        type: Object,
        required: true
      },
      frequency: {
        type: String,
        required: true
      },
      canView: {
        type: Object,
        required: false
      }
    },
    data() {
      return {
        supportDomain: process.env.VUE_APP_SUPPORT_DOMAIN,
        osToTroubleshootAnchor: {
          windows: 'windows',
          android: 'android',
          chromebook: 'chromebook',
          kindle: 'kindle',
          doh: 'iphoneipad',
          mac: 'mac',
          linux: 'linux'
        }
      }
    },
    mounted() {


    },
    methods: {
      onShowTamperEvents() {
        this.$emit('on-events', this.eventStats, tamperDetected)
      },
      onShowEvents() {
        this.$emit('on-events', this.eventStats)
      },
      onShowWebsites() {
        this.$emit('on-websites', this.eventStats)
      },
      onShowTime() {
        this.$emit('on-time', this.eventStats)
      },
      onShowHighRiskWebsites() {
        this.$emit('on-websites', this.eventStats, highRisk, this.frequency === 'weekly' ? 7 : 1)
      },
      onShowHighRiskScreenshots() {
        this.$emit('on-screenshots', this.eventStats, highRisk, this.frequency === 'weekly' ? 7 : 1)
      },
      onShowScreenshots() {
        this.$emit('on-screenshots', this.eventStats, undefined, this.frequency === 'weekly' ? 7 : 1)
      }
    },
    computed: {
      mergedCanView() {
        if(this.canView) {
          return this.canView
        } else {
          return {
            screenshots: true,
            screenshotMetaData: true,
            websites: true,
            screenAppTime: true,
            eventTimeline: true,
            highRiskOnly: false
          }
        }
      },
      getPastTimeString() {
        if(this.frequency === 'weekly') {
          return '7 days'
        } else {
          return '24 hours'
        }
      }
    }
  }
</script>

<style scoped>

</style>