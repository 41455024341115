var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticStyle: { height: "100%" },
      attrs: { outlined: "", elevation: "8" }
    },
    [
      _c(
        "v-card-title",
        { staticStyle: { "text-overflow": "fade", "overflow-x": "hidden" } },
        [_vm._v("\n      " + _vm._s(_vm.eventStats.deviceName) + "\n    ")]
      ),
      _vm.eventStats.deviceMarketName
        ? _c("v-card-subtitle", { staticClass: "pb-1" }, [
            _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.eventStats.deviceMarketName) +
                  "\n      "
              )
            ]),
            _vm.eventStats.appVersion
              ? _c("span", { staticClass: "float-right" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s("v" + _vm.eventStats.appVersion) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "v-card-text",
        { staticClass: "pb-4" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              !_vm.eventStats || _vm.eventStats.totalEvents === 0
                ? _c(
                    "v-col",
                    { staticClass: "pr-1", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-1",
                          attrs: {
                            border: "left",
                            icon: "info",
                            dense: "",
                            outlined: "",
                            color: "secondary"
                          }
                        },
                        [
                          _vm.eventStats.os === "windows" &&
                          _vm.eventStats.userAgent.indexOf("aws-apig-java") !==
                            -1
                            ? [
                                _c("div", [
                                  _vm._v(
                                    "Please upgrade this device to the latest version.  See "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "https://blog.truple.io/2020/03/25/windows-migration-guide.html",
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v("the upgrade guide")]
                                  ),
                                  _vm._v(" for instructions.")
                                ]),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-alert",
                                      {
                                        staticClass: "mb-1 clickable",
                                        attrs: {
                                          icon: "wallpaper",
                                          dense: "",
                                          border: "left",
                                          "colored-border": "",
                                          color: "primary",
                                          elevation: "2"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.onShowScreenshots.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s("View Screenshots") +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            : [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      "No event stats recorded in the past " +
                                        _vm.getPastTimeString +
                                        "!"
                                    )
                                  )
                                ]),
                                _c("div", { staticClass: "caption" }, [
                                  _vm._v(
                                    "If this device is being used, please follow "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "https://" +
                                          _vm.supportDomain +
                                          "/articles/misc/no-events-captured#" +
                                          _vm.osToTroubleshootAnchor[
                                            _vm.eventStats.os
                                          ],
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(" these instructions")]
                                  ),
                                  _vm._v(".")
                                ])
                              ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.eventStats.totalTamperEvents > 0
                ? _c(
                    "v-col",
                    { staticClass: "pr-1", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-1 clickable",
                          attrs: { border: "left", dense: "", type: "error" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onShowTamperEvents.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s("Tamper events!") +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.eventStats.totalHighRiskScreenshots > 0 &&
              _vm.mergedCanView.screenshots
                ? _c(
                    "v-col",
                    { staticClass: "pr-1", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-1 clickable",
                          attrs: { border: "left", dense: "", type: "warning" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onShowHighRiskScreenshots.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s("High risk screenshots!") +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.eventStats.totalHighRiskWebsites > 0 &&
              _vm.mergedCanView.websites
                ? _c(
                    "v-col",
                    { staticClass: "pr-1", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-1 clickable",
                          attrs: { border: "left", dense: "", type: "warning" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onShowHighRiskWebsites.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s("High risk websites!") +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.eventStats.totalScreenshots === 0 &&
              _vm.eventStats.os !== "doh" &&
              _vm.mergedCanView.screenshots
                ? _c(
                    "v-col",
                    { staticClass: "pr-1", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-1",
                          attrs: { border: "left", dense: "", type: "warning" }
                        },
                        [
                          _vm._v(
                            "\n            No screenshots captured\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.eventStats.os !== "doh" && _vm.mergedCanView.screenshots
                ? _c(
                    "v-col",
                    { staticClass: "pr-1", attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-1 clickable",
                          attrs: {
                            icon: "wallpaper",
                            dense: "",
                            border: "left",
                            "colored-border": "",
                            color: "primary",
                            elevation: "2"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onShowScreenshots.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.eventStats.totalScreenshots + " Screenshots"
                              ) +
                              "\n            "
                          ),
                          _vm.mergedCanView.highRiskOnly
                            ? _c(
                                "div",
                                { staticClass: "caption secondary--text" },
                                [
                                  _vm._v(
                                    "(you are limited to high risk screenshots)"
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      (_vm.eventStats.totalScreenshots > 1200 &&
                        _vm.frequency === "daily") ||
                      (_vm.eventStats.totalScreenshots > 1200 * 7 &&
                        _vm.frequency === "weekly")
                        ? _c(
                            "a",
                            {
                              staticClass: "caption",
                              attrs: {
                                href:
                                  "https://" +
                                  _vm.supportDomain +
                                  "/articles/misc/excessive-screenshots",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "(excessive screenshots recorded, please increase time between screenshots)"
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.eventStats.os !== "mac" &&
              _vm.eventStats.os !== "linux" &&
              _vm.mergedCanView.websites
                ? _c(
                    "v-col",
                    {
                      staticClass: "pr-1",
                      attrs: {
                        cols: "12",
                        md: _vm.eventStats.os === "doh" ? 12 : 6
                      }
                    },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-1 clickable",
                          attrs: {
                            icon: "public",
                            dense: "",
                            border: "left",
                            "colored-border": "",
                            color: "accent",
                            elevation: "2"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onShowWebsites.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.eventStats.totalWebsites +
                                  " Websites " +
                                  (_vm.eventStats.os === "doh"
                                    ? "(through DNS)"
                                    : "")
                              ) +
                              "\n            "
                          ),
                          _vm.mergedCanView.highRiskOnly
                            ? _c(
                                "div",
                                { staticClass: "caption secondary--text" },
                                [
                                  _vm._v(
                                    "(you are limited to high risk websites)"
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.eventStats.os === "doh" && _vm.mergedCanView.websites
                ? _c("v-col", { staticClass: "pr-1", attrs: { cols: "12" } }, [
                    _c("span", { staticClass: "caption font-italic" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://" +
                              _vm.supportDomain +
                              "/articles/ios/ios-accountability-explained",
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            "iPhone/iPad websites are recorded differently from other platforms."
                          )
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.mergedCanView.eventTimeline
                ? _c(
                    "v-col",
                    {
                      staticClass: "pr-1",
                      attrs: {
                        cols: "12",
                        md: _vm.eventStats.os === "doh" ? 12 : 6
                      }
                    },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-1 clickable",
                          attrs: {
                            icon: "import_export",
                            border: "left",
                            "colored-border": "",
                            color: "secondary",
                            elevation: "2",
                            dense: ""
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onShowEvents.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.eventStats.totalEvents + " Events") +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.eventStats.os === "android" ||
                _vm.eventStats.os === "kindle" ||
                _vm.eventStats.os === "windows") &&
              _vm.mergedCanView.screenAppTime
                ? _c(
                    "v-col",
                    { staticClass: "pr-1", attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-1 clickable",
                          attrs: {
                            icon: "access_time",
                            color: "secondary",
                            border: "left",
                            "colored-border": "",
                            elevation: "2",
                            dense: ""
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onShowTime.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("\n            Screen & App Time\n          ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.eventStats.os === "doh"
                ? _c("v-col", { attrs: { cols: "12" } })
                : _vm._e(),
              _c(
                "v-col",
                {
                  staticClass: "caption font-italic text-right",
                  staticStyle: {
                    position: "absolute",
                    bottom: "0",
                    right: "10px"
                  },
                  attrs: { cols: "12" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s("in the past " + _vm.getPastTimeString) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }